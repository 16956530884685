import {
  LibraryComplexity,
  LibraryComplexityRole,
  LibraryComponent,
  LibraryComponentEntry,
} from '@app/features/library-management/store/models/component'
import {
  LibraryManagementEntry,
  LibraryManagementItem,
} from '@app/features/library-management/store/models/library-items'
import { MappedEntity } from '@app/core/model/definitions/mapped-entity.interface'
import { sum, sumBy } from 'lodash'
import { LibraryItemName, LibraryItemTypes } from '@app/features/library-management/store/enums'
import { AuthService } from '@app/core/service/auth.service'
import { ComplexitiesList } from '@app/core/model/enums/complexity.enum'
import { Department } from '@app/features/scoping/models/department.model'
import { Injectable } from '@angular/core'
import { LanguageService } from '@app/core/service/language.service'
import { LibraryDeliverableEntry } from '@app/features/library-management/store/models/deliverable'
import { LibraryHighlightsEntry } from '@app/features/library-management/store'
import { LibraryManagementFolder } from '@app/features/library-management/store/models/library-folders'
import { Money } from '@app/features/scope-overview/model/money.model'
import { Preference } from '@app/core/model/user-preferences.interface'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { TableColumnKey } from '@app/shared/components/ui-components/scope-ui-table/table-column-key.enum'
import { TableColumnPreferences } from '@app/shared/components/ui-components/scope-ui-table/table-column-preferences.type'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'
import { User } from '@app/core/model/user.model'
import { formatMonetaryValue } from '@app/shared/utils/utils'
import { of } from 'rxjs'
import { plainToInstance } from 'class-transformer'

@Injectable({ providedIn: 'root' })
export class LibraryManagementMappingService {
  constructor(private authService: AuthService, private lang: LanguageService) {}

  mapLibraryItems(
    items: LibraryManagementItem[],
    preferences: TableColumnPreferences,
    childPreferences: TableColumnPreferences
  ): MappedEntity<LibraryManagementItem>[] {
    return (items || []).map((data) =>
      Object.entries(preferences).reduce(
        (newObj, [key, config]) => {
          if (config.selected) {
            newObj[key] =
              (!config.requiredPrivilege ||
                data.hasPrivilege(config.requiredPrivilege, this.authService.loggedInUser!)) &&
              (!config.isVisible || config.isVisible(data))
                ? config.value
                  ? config.value(data)
                  : this.getValueByPath(data, config.field || '')
                : config.default

            if (key === TableColumnKey.LIBRARY_ITEM_TYPE)
              newObj[key] = `${newObj.entity.fixedPricing ? 'Fixed Fee ' : ''}${this.lang.get(
                LibraryItemName[newObj[key]]
              )}`
          }

          return newObj
        },
        {
          entity: data,
          children$: of(
            this.mapLibraryItemEntries(
              data.libraryItemType === LibraryItemTypes.COMPONENT
                ? data.libraryComponentEntries
                : data.libraryDeliverableEntrySet,
              childPreferences
            )
          ),
          isNotExpandable: data.libraryItemType === LibraryItemTypes.THIRD_PARTY_COST,
        } as MappedEntity<LibraryManagementItem>
      )
    )
  }

  mapLibraryItemEntries(data: LibraryManagementEntry[], preferences: TableColumnPreferences) {
    return (data || []).map((entity) => {
      entity = plainToInstance(LibraryManagementEntry, entity)

      return Object.entries(preferences).reduce(
        (newObj, [key, config]) => {
          if (config.selected) {
            if (key === 'VALUE') {
              newObj[key] = entity.deliverable
                ? entity.hasPrivilege(config.requiredPrivilege, this.authService.loggedInUser!) &&
                  (!config.isVisible || config.isVisible(entity))
                  ? entity.getValue()
                  : config.default
                : (newObj[key] = entity.getAvailableSizes())
            } else {
              newObj[key] =
                !config.isVisible || config.isVisible(entity)
                  ? config.value
                    ? config.value(entity)
                    : this.getValueByPath(entity, config.field || '')
                  : config.default
            }
          }

          return newObj
        },
        { entity } as MappedEntity<LibraryManagementEntry>
      )
    })
  }

  mapFolders(
    items: LibraryManagementFolder[],
    preferences: TableColumnPreferences
  ): MappedEntity<LibraryManagementFolder>[] {
    return (items || []).map((data) =>
      Object.entries(preferences).reduce(
        (newObj, [key, config]) => {
          if (config.selected) {
            newObj[key] =
              (!config.requiredPrivilege ||
                data.hasPrivilege(config.requiredPrivilege, this.authService.loggedInUser!)) &&
              (!config.isVisible || config.isVisible(data))
                ? config.value
                  ? config.value(data)
                  : this.getValueByPath(data, config.field || '')
                : config.default
          }

          if (key === TableColumnKey.LIBRARY_NAME && newObj.entity.isSharedByCompanies)
            newObj[key] += ` (${newObj.entity.sharedCompaniesNames})`

          if (key === TableColumnKey.LIBRARY_ITEM_TYPE)
            newObj[key] = this.lang.get(LibraryItemName[newObj.entity.libraryItemType])

          return newObj
        },
        {
          entity: data,
          isNotExpandable: true,
        } as MappedEntity<LibraryManagementFolder>
      )
    )
  }

  mapDeliverableEntries = (entries: LibraryDeliverableEntry[]): LibraryDeliverableEntry[] =>
    entries.map((entry) => plainToInstance(LibraryDeliverableEntry, entry))

  mapDeliverableEntry = (entry: LibraryDeliverableEntry): LibraryDeliverableEntry =>
    plainToInstance(LibraryDeliverableEntry, {
      ...entry,
      libraryComponents: entry.libraryComponents?.map((component) => ({
        ...component,
        agencyPrice: formatMonetaryValue(component.agencyPrice),
        agencyHours: sum(component?.roles?.map((r) => r.hours || 0)),
      })),
      thirdPartyCosts: entry.thirdPartyCosts?.map((tpc) => {
        const tpcInstance = plainToInstance(ThirdPartyCost, tpc)

        return {
          ...tpcInstance,
          agencyPrice: tpcInstance.calculateSellingPrice(),
          overtimeRate: {
            amount: tpcInstance.formula?.overtimeRate,
            currency: tpcInstance.cost?.currency,
          },
        }
      }),
    })

  mapComponent = (component: LibraryComponent): LibraryComponent =>
    plainToInstance(LibraryComponent, {
      ...component,
      createdBy: plainToInstance(User, component.createdBy),
      libraryComponentEntries: component.libraryComponentEntries?.map((entry) =>
        plainToInstance(LibraryComponentEntry, {
          ...entry,
          createdBy: plainToInstance(User, entry.createdBy),
          componentComplexities: ComplexitiesList.reduce(
            (result, complexity) => ({
              ...result,
              [complexity]: entry?.componentComplexities?.[complexity] || { complexity, description: '' },
            }),
            {}
          ),
        })
      ),
    })

  mapComponentComplexities = (
    entry: LibraryComponentEntry,
    rateCard: RatecardVersion,
    rateCardDepartmentsMap: { [key: number]: Department },
    rateCardDepartmentByRoleIdMap: { [key: number]: number },
    preferences?: Preference[]
  ): MappedEntity<LibraryComplexity>[] => {
    let currency = rateCard?.currencyCode

    let complexities = ComplexitiesList.reduce((result, key) => {
      let complexity = entry?.componentComplexities?.[key]
      let departments = []

      if (complexity) {
        const complexityRolesByDepartmentId = this.mapComplexityRolesByDepartmentId(
          complexity.roles,
          rateCardDepartmentByRoleIdMap,
          currency
        )

        departments = this.mapComplexityDepartments(complexityRolesByDepartmentId, rateCardDepartmentsMap, currency)
      }

      return [
        ...result,
        plainToInstance(LibraryComplexity, {
          ...complexity,
          name: complexity?.aliasName || entry?.name,
          departments,
          fixedPricing: entry?.fixedPricing,
          fixedCost: complexity?.fixedCost ? {
            ...complexity?.fixedCost,
            currency
          } : null,
          agencyHours: sumBy(departments, (d) => d.agencyHours),
          agencyPrice: plainToInstance(Money, {
            amount: sumBy(departments, (d) => d.agencyPrice.amount) || 0,
            currency,
          }),
          agencyCost: plainToInstance(Money, {
            amount: complexity?.costAmount || sumBy(departments, (d) => d.agencyCost.amount) || 0,
            currency,
          }),
        }),
      ]
    }, [])

    return this.mapComplexitiesWithPrivileges(
      complexities,
      preferences,
      entry?.fixedPricing && !entry?.fixedFeeHasRoles
    )
  }

  mapComplexityRolesByDepartmentId = (
    roles: LibraryComplexityRole[] = [],
    rateCardDepartmentByRoleIdMap: { [key: number]: number } = {},
    currency: string
  ): { [key: number]: LibraryComplexityRole[] } =>
    roles?.reduce((res, role) => {
      const departmentId = rateCardDepartmentByRoleIdMap?.[role.rateCardRole.id]
      if (!res[departmentId]) res[departmentId] = []
      res[departmentId] = [
        ...(res[departmentId] || []),
        {
          ...role,
          agencyPrice: plainToInstance(Money, {
            amount: (role.minutes * role.rateCardRole.rate.amount) / 60,
            currency,
          }),
          agencyCost: plainToInstance(Money, {
            amount: (role.minutes * role.rateCardRole.cost.amount) / 60,
            currency,
          }),
        },
      ]
      return res
    }, {})

  mapComplexityDepartments = (
    rolesMap: { [key: number]: LibraryComplexityRole[] } = {},
    departmentsMap: { [key: number]: Department } = {},
    currency: string
  ) =>
    Object.keys(rolesMap).reduce((res, id) => {
      const roles: LibraryComplexityRole[] = rolesMap[id]

      return [
        ...res,
        {
          ...departmentsMap?.[id],
          roles,
          agencyHours: sumBy(roles, (role) => role.minutes) / 60,
          agencyPrice: plainToInstance(Money, {
            amount: sumBy(roles, (role) => (role.minutes * role.rateCardRole.rate.amount) / 60),
            currency,
          }),
          agencyCost: plainToInstance(Money, {
            amount: sumBy(roles, (role) => (role.minutes * role.rateCardRole.cost.amount) / 60),
            currency,
          }),
        },
      ]
    }, [])

  mapComplexitiesWithPrivileges = (
    complexities: LibraryComplexity[],
    preferences: Preference[],
    isNotExpandable: boolean
  ) =>
    (complexities || []).map((data) =>
      preferences.reduce(
        (newObj, config) => {
          if (config.selected)
            newObj[config.key] =
              (!config.requiredPrivilege ||
                data.hasPrivilege?.(config.requiredPrivilege, this.authService.loggedInUser!)) &&
              (!config.isVisible || config.isVisible(data))
                ? config.value
                  ? config.value(data)
                  : this.getValueByPath(data, config.field || '')
                : config.default
          return newObj
        },
        { entity: data, isNotExpandable } as MappedEntity<LibraryComplexity>
      )
    )

  mapRoleToComplexityRole = (role: LibraryComplexityRole): LibraryComplexityRole =>
    plainToInstance(LibraryComplexityRole, {
      id: role.id,
      minutes: role.minutes,
      rateCardRole: role.rateCardRole,
    })

  mapHighlightsEntry = (entry): LibraryHighlightsEntry[] =>
    Object.keys(entry).map((key) => ({ id: parseInt(key), name: entry[key] }))

  mergePreferencesWithTableConfig(baseConfig: Record<any, Preference>, userPreferences: Record<any, Preference>) {
    let mergedConfig: Record<any, Preference> = { ...baseConfig }

    Object.keys(baseConfig).forEach((key) => {
      if (userPreferences?.[key]) mergedConfig[key] = { ...baseConfig[key], selected: userPreferences[key].selected }
    })
  }

  private getValueByPath(obj: any, path: string): any {
    let value = path.split('.').reduce((acc, part) => acc && acc[part], obj)
    return value != null ? value : '-'
  }
}
