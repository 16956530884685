export enum TableColumnKey {
  TOGGLE_LIST = 'TOGGLE_LIST',
  TOGGLE = 'TOGGLE',
  DATE_CREATED = 'DATE_CREATED',
  DATE_APPROVED = 'DATE_APPROVED',
  LAST_EDITED = 'LAST_EDITED',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  CREATED_BY = 'CREATED_BY',
  BUDGET = 'BUDGET',
  BALANCE = 'BALANCE',
  VALUE = 'VALUE',
  PROFIT = 'PROFIT',
  OWNER = 'OWNER',
  SIZE = 'SIZE',
  ROLE = 'ROLE',
  HOURS = 'HOURS',
  QUANTITY = 'QUANTITY',
  FTE = 'FTE',
  SCOPEMARK_PRICE = 'SCOPEMARK_PRICE',
  SCOPEMARK_HOURS = 'SCOPEMARK_HOURS',
  AGENCY_PRICE = 'AGENCY_PRICE',
  EDITABLE_AGENCY_PRICE = 'EDITABLE_AGENCY_PRICE',
  EDITABLE_AGENCY_COST = 'EDITABLE_AGENCY_COST',
  AGENCY_HOURS = 'AGENCY_HOURS',
  AGENCY_RATE = 'AGENCY_RATE',
  AGENCY_COST = 'AGENCY_COST',
  WEIGHTED_RATE = 'WEIGHTED_RATE',
  UNIT_COST = 'UNIT_COST',
  SELLING_PRICE = 'SELLING_PRICE',
  PRICE = 'PRICE',
  AMOUNT = 'AMOUNT',
  STATUS = 'STATUS',
  MARK_UP = 'MARK_UP',
  MARGIN = 'MARGIN',
  MANDATORY = 'MANDATORY',
  SCOPE_NUMBER = 'SCOPE_NUMBER',
  SECOND_PARTY = 'SECOND_PARTY',
  CREATOR = 'CREATOR',
  FEE_NAME = 'FEE_NAME',
  TPC_NAME = 'TPC_NAME',
  LINK_TO = 'LINK_TO',
  SECTION_NAME = 'SECTION_NAME',
  DEPARTMENT_NAME = 'DEPARTMENT_NAME',
  DELIVERABLE_NAME = 'DELIVERABLE_NAME',
  SOW_NAME = 'SOW_NAME',
  ROLE_NAME = 'ROLE_NAME',
  LIBRARY_NAME = 'LIBRARY_NAME',
  COMPONENT_NAME = 'COMPONENT_NAME',
  EDITABLE_NAME = 'EDITABLE_NAME',
  DELIVERY_STATUS = 'DELIVERY_STATUS',
  SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS = 'SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS',
  UNIT = 'UNIT',
  UNIT_TYPE = 'UNIT_TYPE',
  OVERTIME_UNIT_TYPE = 'OVERTIME_UNIT_TYPE',
  OVERTIME_QUANTITY = 'OVERTIME_QUANTITY',
  OVERTIME_RATE = 'OVERTIME_RATE',
  OVERTIME_TOTAL = 'OVERTIME_TOTAL',
  NAME = 'NAME',
  LIBRARY_ITEM_TYPE = 'LIBRARY_ITEM_TYPE',
  RATECARD = 'RATECARD',
  RATECARD_VERSION = 'RATECARD_VERSION',
  RATE_CARD = 'RATE_CARD',
  RATE_CARD_VERSION = 'RATE_CARD_VERSION',
  DELIVERABLE = 'DELIVERABLE',
  REVIEW_STATUS = 'REVIEW_STATUS',
  DISCIPLINE = 'DISCIPLINE',
  TPC_AMOUNT = 'TPC_AMOUNT',
  BRAND = 'BRAND',
  REGION = 'REGION',
  LOCATION = 'LOCATION',
  FIELD_NAME = 'FIELD_NAME',
  FIELD_JSON_KEY = 'FIELD_JSON_KEY',
  FIELD_TYPE = 'FIELD_TYPE',
  DEPARTMENT = 'DEPARTMENT',
  QUESTIONS = 'QUESTIONS',
  FIELD_LABEL = 'FIELD_LABEL',
  FIELD_ID = 'FIELD_ID',
  APPEAR_RULE = 'APPEAR_RULE',
  TYPE = 'TYPE',
  ASSIGNED = 'ASSIGNED',
  COMPLEXITY = 'COMPLEXITY',
  QUANTITY_MODIFIER = 'QUANTITY_MODIFIER',
  PRICE_MODIFIER = 'PRICE_MODIFIER',
}
