import { Injectable } from '@angular/core';
import { CellValue, HyperFormula } from 'hyperformula';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { QuestionType } from '@core/model/enums/question-type.enum';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';
import { ScopeDynamicFieldSettingModel } from '@app/features/scoping/models/scope-dynamic-field-setting.model';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';
import { ComponentModifier } from '@app/features/company-management/models/component-modifier.model';

@Injectable({
  providedIn: 'root',
})
export class FormulaService {
  private hf: HyperFormula
  private sheetId: number
  private excludedFields: [string, CellValue][] = []

  constructor() {
    this.hf = HyperFormula.buildEmpty({ licenseKey: 'gpl-v3' })
  }

  initialise(scenario: Scenario, dynamicFields?: ScopeDynamicFieldSettingModel[]) {
    if (!this.hf.doesSheetExist(scenario.name)) {
      const sheetName = this.hf.addSheet(scenario.name)
      this.sheetId = this.hf.getSheetId(sheetName)
    }

    this.hf.listNamedExpressions().forEach((e) => this.hf.removeNamedExpression(e))
    this.hf.addNamedExpression('TRUE', '=TRUE()');
    this.hf.addNamedExpression('FALSE', '=FALSE()');

    scenario.categories.flatMap((c) => c.questions).forEach((q) => {
      if (!q.fieldId) {
        return;
      }
      let value = q.value
      if (q.type === QuestionType.FORMULA && q.formula) {
        value = `${q.formula.substring(0, 1) != '=' ? '=' : ''}${q.formula.replace('!=', '<>')}`
      }
      let excludedField = this.excludedFields.find((e) => e[0] == q.fieldId)
      if (excludedField) {
        excludedField[1] = value
      } else if (!this.hf.isItPossibleToAddNamedExpression(q.fieldId, value)) {
        console.error(`Field ${q.fieldLabel} has an invalid field id (${q.fieldId}) and cannot be used in formulas`)
      } else {
        this.hf.addNamedExpression(q.fieldId, value)
      }
    })

    dynamicFields?.forEach((f) => {
      let value = f.value
      if (!this.hf.isItPossibleToAddNamedExpression(f.name, value)) {
        console.error(`Field ${f.name} has an invalid field id (${f.name}) and cannot be used in formulas`)
      } else {
        this.hf.addNamedExpression(f.name, value)
      }
    })
  }

  updateField(question: ScenarioQuestion) {
    if (question.fieldId) {
      try {
        this.hf.changeNamedExpression(question.fieldId, question.value)
      } catch {}
    }
  }

  getFields() {
    return this.hf.listNamedExpressions()
      .filter((tag) => tag != 'TRUE' && tag != 'FALSE')
      .map((f) => { return { value: f, type: 'field' } })
  }

  getFunctions() {
    return this.hf.getAllFunctionPlugins().reduce(function (r, a) {
      return Object.assign(r, a.implementedFunctions);
    }, {})
  }

  calculate(formula: string) {
    return this.hf.calculateFormula(
      `${formula.substring(0, 1) != '=' ? '=' : ''}${formula.replace('!=', '<>')}`,
      this.sheetId)
  }

  getReferencedFields(formula: string) {
    return this.hf.getNamedExpressionsFromFormula(
      `${formula.substring(0, 1) != '=' ? '=' : ''}${formula.replace('!=', '<>')}`
    ).map((e) => e.toLowerCase())
  }

  setExcludedFields(excludedFields: string[]) {
    this.excludedFields.forEach((e) => this.hf.addNamedExpression(e[0], e[1] as string))
    this.excludedFields = []
    excludedFields.forEach((e) => {
      let expression = this.hf.getNamedExpression(e)
      if (expression) this.hf.removeNamedExpression(e)
      this.excludedFields.push([e, expression?.expression])
    })
  }

  getReferencedFieldsForQuestion(fieldId: string, scenario: Scenario, modifiers: ComponentModifier[]) {
    let questionDependencies = scenario.categories
      .flatMap((c) => c.questions.map((q) => {
        return { ...q, categoryName: c.name }
      }))
      .filter((q) =>
        (q.formula && this.getReferencedFields(q.formula).includes(fieldId.toLowerCase())) ||
        (q.displayCondition && this.getReferencedFields(q.displayCondition).includes(fieldId.toLowerCase()))
      );
    let modifierDependencies = modifiers.filter((m) =>
      m.formula && this.getReferencedFields(m.formula).includes(fieldId.toLowerCase())
    );
    return ({questionDependencies, modifierDependencies})
  }

  getReferencedFieldsForCategory(category: ScenarioCategory, scenario: Scenario, modifiers: ComponentModifier[]) {
    let fieldIds = category.questions.flatMap((q) => q.fieldId?.toLowerCase());
    let questionDependencies = scenario.categories
      .filter((c) => c.id != category.id)
      .flatMap((c) => c.questions.map((q) => {
        return { ...q, categoryName: c.name }
      }))
      .filter((q) =>
        (q.formula && this.getReferencedFields(q.formula).some((f) => fieldIds.includes(f))) ||
        (q.displayCondition && this.getReferencedFields(q.displayCondition).some((f) => fieldIds.includes(f)))
      );
    let modifierDependencies = modifiers.filter((m) =>
      m.formula && this.getReferencedFields(m.formula).some((f) => fieldIds.includes(f))
    );
    return ({questionDependencies, modifierDependencies})
  }
}
