<div class="top-panel">
  <div class="justify-start">
    <button class="text-link" aria-label="Back to Templates gallery" (click)="backToScenarios()">
      <mat-icon class="material-symbols-rounded orange">chevron_left</mat-icon>
      Back to Scenario Pricing Configurator
    </button>
  </div>
</div>
<div class="flex scenario-header-container items-center">
  <div class="scope-overview-header-title">
    <div class="scope-name" *ngIf="(scenario$ | async) as scenario">
      <h3 class="product-heading-bold" *ngIf="!editScenarioName">{{scenario?.name}}</h3>
      <mat-icon (click)="editScenarioName = true; $event.stopPropagation()" *ngIf="!editScenarioName"
                class="material-symbols-rounded fill orange text-base ml-1.5 pointer">mode_edit
      </mat-icon>
      <scope-ui-input
        *ngIf="editScenarioName"
        class="edit-name"
        (click)="$event.stopPropagation()"
        [initialValue]="scenario.name"
        [autofocus]="true"
        (onInputBlurChange)="renameScenario($event, scenario)"
      />
    </div>
  </div>
</div>
<div class="scenario-container">
  <scope-ui-tabs (onSelect)="tab = $event" (onInit)="tab = 'Configuration form'">
    <scope-ui-tab tabTitle="Configuration form">
      <div class="py-3" *ngIf="tab == 'Configuration form'">
        <div class="flex gap-2 items-center justify-end mb-1">
          <scope-ui-input
            class="z-10"
            icon="search"
            cancelIcon="cancel"
            inputLabel="Quick Search"
            inputClass="search-field search-field-lg"
            (onCancel)="searchText = ''; filterCategories()"
            (onInputChange)="searchText = $event; filterCategories()" />
          <mat-divider vertical />
          <button class="z-10" (click)="openCreateModal()" mat-flat-button>
            <mat-icon class="material-symbols-rounded">add</mat-icon>
            Create Category
          </button>
        </div>
        <scope-ui-table
          tableClass="overview-table shrunk-table"
          [loggedInUser]="loggedInUser"
          [data]="mappedCategoriesDataSource$ | async"
          [loading]="loading$ | async"
          [displayedColumns]="categoryPreferenceColumns"
          [menuOptions]="categoryMenuOptions"
          [noPagination]="true"
          [noPreferences]="true"
          [rowGaps]="true"
          [expandable]="true"
          [showExpandAll]="true"
          [showToggleMenu]="true"
          [addToggleListColumn]="true"
          [enableDragging]="true"
          [checkDropLocation]="checkCategoryDependencies"
          (orderChange)="reorderCategory($event)">
          <ng-template let-element>
            <ng-container *ngIf="element.entity as category">
              <scope-ui-table
                tableClass="nested condensed"
                [isSubTable]="true"
                [loggedInUser]="loggedInUser"
                [loading]="loading$ | async"
                [unmappedData]="category.questions | orderBy: 'orderIndex'"
                [displayedColumns]="questionsPreferenceColumns"
                [menuOptions]="questionsMenuOptions"
                [noPagination]="true"
                [noPreferences]="true"
                [showToggleMenu]="true"
                [addToggleListColumn]="true"
                [condensed]="true"
                [parentEntity]="category"
                [selectMode]="ScopeUiTableSelectMode.ROW"
                [enableDragging]="true"
                [checkDropLocation]="checkQuestionDependencies"
                [lineClamp]="2"
                (orderChange)="reorderQuestion($event, category)"
                (onSelect)="editQuestion($event, category)"/>
            </ng-container>
          </ng-template>
        </scope-ui-table>
      </div>
    </scope-ui-tab>
    <scope-ui-tab tabTitle="{{('component' | lang)}} modifiers">
      <div class="py-3" *ngIf="tab && tab !== 'Configuration form'">
        <component-modifiers [scenario]="scenario$ | async" />
      </div>
    </scope-ui-tab>
  </scope-ui-tabs>
</div>
