import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { CellValue, DetailedCellError, ImplementedFunctions } from 'hyperformula';
import { FormControl } from '@angular/forms';
import Prism from 'prismjs';
import 'prismjs/components/prism-excel-formula';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';
import { FormulaService } from '@shared/services/formula.service';
import { QuestionType } from '@core/model/enums/question-type.enum';
import { ScopeDynamicFieldSettingModel } from '@app/features/scoping/models/scope-dynamic-field-setting.model';

declare var codeInput: any
type Function = { value: string, description: string, brackets?: boolean }
type FunctionMap = { [key: string]: Function[] }

@Component({
  selector: 'formula-builder',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './formula-builder.component.html',
  styleUrls: ['./formula-builder.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FormulaBuilderComponent implements AfterViewInit, OnChanges {
  @ViewChild('formulaInput') formulaInput: ElementRef
  @ViewChild('formulaReadOnly') formulaReadOnly: ElementRef

  @Input() title = 'Formula'
  @Input() set value(value: string) {
    this.formula = value
    if (this.formula) this.calculate()
  }
  @Input() set excludedFields(value: string[]) {
    this._excludedFields = value
    this.formulaService.setExcludedFields(value || [])
  }
  @Input() set scenario(value: Scenario) {
    this._scenario = value
  }
  @Input() dynamicFields: ScopeDynamicFieldSettingModel[]
  @Input() showResult: boolean = true
  @Input() showInput: boolean = true
  @Input() required: boolean = false
  @Input() control: FormControl = new FormControl()
  @Input() set showFormula(value: boolean) {
    this._showFormula = value
    if (value) {
      codeInput.registerTemplate("default", codeInput.templates.prism(Prism, []))
      if (this.formulaReadOnly) {
        this.formulaReadOnly.nativeElement.textareaElement.setAttribute("disabled", true)
        this.formulaReadOnly.nativeElement.value = this.formula
      }
    }
  }

  @Output() valueChange = new EventEmitter<string>()
  @Output() updateResult = new EventEmitter<string>()
  @Output() focus = new EventEmitter<void>()

  _scenario: { name: string, categories: any[]}
  _excludedFields: string[] = []
  formula: string
  formulaError: string
  functionExample: string
  result: CellValue
  mode: 'Field' | 'Function'
  functionMode: 'Arithmetic' | 'Logic' | 'Comparison'
  functions: FunctionMap = {
    'Arithmetic': [{value: '+', description: 'Add value'},
      {value: '-', description: 'Subtract value/Make value negative'},
      {value: '*', description: 'Multiply'},
      {value: '/', description: 'Divide'},
      {value: '%', description: 'Percentage'},
      {value: '^', description: 'Exponential'}],
    'Logic': [
      {value: 'IF', description: 'Specifies a logical test to be performed. IF(Test, Then_value, Otherwise_value)', brackets: true},
      {value: 'AND', description: 'Returns TRUE if all arguments are TRUE', brackets: true},
      {value: 'OR', description: 'Returns TRUE if at least one argument is TRUE', brackets: true},
      {value: 'NOT', description: 'Complements (inverts) a logical value', brackets: true},
      {value: 'TRUE', description: 'The logical value TRUE'},
      {value: 'FALSE', description: 'The logical value FALSE'}],
    'Comparison': [{value: '<', description: 'Less than'},
      {value: '>', description: 'Greater than'},
      {value: '<=', description: 'Less than or equal'},
      {value: '>=', description: 'Greater than or equal'},
      {value: '=', description: 'Equal'},
      {value: '!=', description: 'Not equal'}, ]
  }
  selectedCategory: ScenarioCategory
  showFields: boolean = false
  functionOptions = this.functions['Logic']
    .map((f) => { return { ...f, type: 'function' } })
  implementedFunctions: ImplementedFunctions
  functionExampleMap: { [key: string]: string } = {}
  _showFormula: boolean
  private popupElem: HTMLElement

  constructor(private cdr: ChangeDetectorRef, private formulaService: FormulaService) {
    this.implementedFunctions = this.formulaService.getFunctions()
    if (this.showInput) {
      codeInput.registerTemplate("default", codeInput.templates.prism(Prism, [
        new codeInput.plugins.Autocomplete(this.autocomplete),
        new codeInput.plugins.AutoCloseBrackets()
      ]))
    }
  }

  ngAfterViewInit() {
    if (this.showInput) {
      this.formulaInput.nativeElement.value = this.formula
      this.formulaInput.nativeElement.textareaElement.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Enter') event.preventDefault()
      })
      this.formulaInput.nativeElement.textareaElement.addEventListener('input', (event: InputEvent) => {
        let textarea: any = event.target
        textarea.parentElement.value = textarea.value.replace("\n", "")
        this.formula = textarea.parentElement.value
        this.valueChange.emit(this.formula)
        this.calculate()
      })
      this.formulaInput.nativeElement.textareaElement.addEventListener('selectionchange', () => {
        if (this.formula) {
          this.setFunctionName()
          this.cdr.detectChanges()
        }
      })
      this.formulaInput.nativeElement.textareaElement.addEventListener('focus', () => {
        this.focus.emit()
      })
    }
    if (this._showFormula && this.formulaReadOnly) {
      this.formulaReadOnly.nativeElement.textareaElement.setAttribute("disabled", true)
      this.formulaReadOnly.nativeElement.value = this.formula
    }
  }

  ngOnChanges() {
    if (this._scenario) {
      this.formulaService.initialise(this._scenario as Scenario, this.dynamicFields)
      if (this.formula) this.calculate()
    }
  }

  hideAutocomplete = (e: MouseEvent) => {
    if (e.target !== this.formulaInput.nativeElement.textareaElement) this.popupElem.innerHTML = ""
  }

  autocomplete = (popupElem: HTMLElement, textarea: any, caretPos: number) => {
    popupElem.inert = false
    popupElem.innerHTML = ""
    if (textarea.value.substring(caretPos, caretPos+1).length &&
      !textarea.value.substring(caretPos, caretPos+1).match(/[^a-zA-Z\d_.:]/)) {
      return
    }
    let tags = textarea.value.substring(0, caretPos).split(/[^a-zA-Z\d_.:]/)
    let search = tags[tags.length-1]
    if (search == "")  return

    let fieldOptions: { value: string, type: string, brackets?: boolean }[] = this.formulaService.getFields()
    fieldOptions.concat(this.functionOptions)
      .sort((a, b) => a.value.localeCompare(b.value))
      .filter((tag) => tag.value != search &&
        tag.value.substring(0, search.length).toLowerCase() == search.toLowerCase())
      .forEach((tag) => {
        let option = document.createElement("button")
        option.innerHTML =
          `<mat-icon class="material-symbols-rounded">${tag.type === 'field' ? 'data_table' : 'function'}</mat-icon>
          <span><b>${tag.value.substring(0, search.length)}</b>${tag.value.substring(search.length, tag.value.length)}</span>`

        option.addEventListener("click", () => {
          this.insert(tag.value, tag.brackets, caretPos - search.length)
        })
        popupElem.appendChild(option)
        const element = option.lastChild as HTMLElement
        if (element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight) {
          element.title = element.innerText
        }
      })

    if (popupElem.offsetLeft > (this.formulaInput.nativeElement.offsetWidth - popupElem.offsetWidth))
      popupElem.style.left = `${this.formulaInput.nativeElement.offsetWidth - popupElem.offsetWidth}px`

    this.popupElem = popupElem
    window.removeEventListener('click', this.hideAutocomplete)
    window.addEventListener('click', this.hideAutocomplete)
  }

  getFunctionNameAtSelection() {
    let textarea = this.formulaInput.nativeElement.textareaElement
    let selectionStart = textarea.selectionStart
    let counter = 0
    let functionName
    this.formula.substring(0, selectionStart).match(/[a-zA-Z\d_.:]*\(|\)/g)?.reverse().some((t) => {
      if (t === ')') {
        counter++
      } else if (counter || t === ')') {
        counter-- || 0
      } else {
        let found = this.implementedFunctions[t.replace('(', '')]
        if (found)
          functionName = t.replace('(', '')
        return found
      }
      return false
    })
    return functionName
  }

  getFunctionExample(functionName: string) {
    let metadata = this.implementedFunctions[functionName]
    let parameters = metadata.parameters.map((p) => {
      let value = p.argumentType == 'SCALAR' ? 'Value' : p.argumentType.charAt(0) + p.argumentType.slice(1).toLowerCase()
      return `${value}${p.optionalArg ? '?' : ''}${p.defaultValue != undefined ? ('(default = ' + p.defaultValue.toString()) + ')' : ''}`
    })
    return `${functionName}(${parameters.join(', ')}${metadata.repeatLastArgs ? ' 1, ...' + parameters[parameters.length - 1] + ' N' : ''})`
  }

  setFunctionName() {
    let functionName = this.getFunctionNameAtSelection()
    if (functionName) {
      if (this.functionExampleMap[functionName]) {
        this.functionExample = this.functionExampleMap[functionName]
      } else {
        this.functionExample = this.functionExampleMap[functionName] = this.getFunctionExample(functionName)
      }
    } else {
      this.functionExample = null
    }
  }

  calculate() {
    if (this.formula?.length) {
      try {
        let result = this.formulaService.calculate(this.formula)
        if (result instanceof DetailedCellError) {
          this.formulaError = result.message
          this.control.setErrors({ invalid: result.message })
          this.result = undefined
        } else {
          this.control.setErrors(null)
          this.result = result as CellValue
        }
        this.updateResult.emit(this.result as string)
        this.cdr.detectChanges()
      } catch (e) {
        this.control.setErrors({ invalid: 'Not a valid formula' })
        this.result = undefined
      }
    } else if (this.required) {
      this.control.setErrors({ required: true })
      this.result = undefined
    }
  }

  selectFields() {
    this.mode = this.mode == 'Field' ? null : 'Field'
    this.functionMode = null
  }

  selectFunctions() {
    this.mode = this.mode == 'Function' ? null : 'Function'
    this.selectedCategory = null
  }

  insert(value: string, brackets: boolean = false, selectionStart?: number) {
    let textarea = this.formulaInput.nativeElement.textareaElement
    if (selectionStart == undefined) selectionStart = textarea.selectionStart
    let newCaretPos = selectionStart + value.length
    if (brackets && textarea.value.substring(textarea.selectionEnd, textarea.selectionEnd+1) != '(') {
      value = value + '()'  // Automatically add brackets
      newCaretPos += 1 // Place cursor inside brackets
    }
    this.formulaInput.nativeElement.value = this.formulaInput.nativeElement.value.substring(0, selectionStart) +
      value + this.formulaInput.nativeElement.value.substring(textarea.selectionEnd)
    textarea.focus()
    textarea.selectionStart = textarea.selectionEnd = newCaretPos
    this.formula = this.formulaInput.nativeElement.value
    this.valueChange.emit(this.formula)
    this.calculate()
  }

  protected readonly QuestionType = QuestionType;
}
