import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Scenario } from '@app/features/scope-overview/model/scenario.model';
import { SharedModule } from '@shared/shared.module';
import { ScenarioCategory } from '@app/features/scope-overview/model/scenario-category.model';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { QuestionType } from '@core/model/enums/question-type.enum';
import { ScopeConfiguration, ScopeVersion, StatusType } from '@core/model/scope-version';
import { cloneDeep, isNil, isNumber } from 'lodash';
import { FormulaBuilderComponent } from '@shared/components/formula-builder/formula-builder.component';
import { ScenarioQuestion } from '@app/features/scope-overview/model/scenario-question.model';
import {
  ScopeUiDatepickerComponent,
} from '@shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component';
import {
  ScopeUiDropdownComponent,
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { Observable, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ScopeOverviewSelectors } from '@app/features/scope-overview/store/selectors/scope-overview.selector';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action';
import { trackById, untilDestroyed } from '@shared/utils/utils';
import { FormulaService } from '@shared/services/formula.service';
import { DetailedCellError } from 'hyperformula';
import { ApprovalFlowService } from '@app/features/scope-overview/service/approval-flow.service';
import { LibraryManagementNgrxModule } from '@app/features/library-management/library-management-ngrx.module';
import { DeliverablePromptOption } from '@app/features/scope-overview/model/deliverable-prompt.model';
import { CompanyManagementActions } from '@app/features/company-management/store/actions/company-management.actions';
import {
  CompanyManagementSelectors,
} from '@app/features/company-management/store/selectors/company-management.selectors';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import { MatDialog } from '@angular/material/dialog';
import { ComponentModifier } from '@app/features/company-management/models/component-modifier.model';
import { FeePromptOption } from '@app/features/scope-overview/model/fee-prompt.model';
import {
  LibraryDeliverableEntryDetailsService
} from '@app/features/library-management/services/library-deliverable-entry-details.service';
import {
  LibraryDeliverableEntryDetails
} from '@app/features/library-management/store/models/deliverable/library-deliverable-entry-details.model';

@Component({
  selector: 'scope-configuration',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ScopeUiInputComponent,
    FormulaBuilderComponent,
    ScopeUiDatepickerComponent,
    ScopeUiDropdownComponent,
    NgOptimizedImage,
    LibraryManagementNgrxModule
  ],
  templateUrl: './scope-configuration.component.html',
  styleUrls: ['./scope-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScopeConfigurationComponent implements OnInit, OnDestroy {
  @Input() set currentScope(scopeVersion: ScopeVersion) {
    this.scopeVersion = scopeVersion
    this.editable = this.approvalFlowService.isScopeEditable(scopeVersion)
    this.configuration = cloneDeep(scopeVersion.configuration || {})
    this.configurationSubmitted = scopeVersion.configurationSubmitted || (scopeVersion.configurationSubmitted === undefined)
  }
  @Output() onSubmit = new EventEmitter<void>()

  private readonly destroy$
  scopeVersion: ScopeVersion
  scenario: Scenario
  scenarioInitialised: boolean = false
  selectedCategoryId?: number
  editable: boolean
  configuration: ScopeConfiguration
  configurationSubmitted: boolean
  saveLoading$: Observable<boolean>
  submitLoading$: Observable<boolean>
  submitLoaded$: Observable<boolean>
  loadingModifiers$: Observable<boolean>
  formSubmitted: boolean = false
  showFormulaMap: { [questionId: number]: boolean } = {}
  categoryStates: { [categoryId: number]: {
      enabled: boolean, completed: boolean, updated?: boolean, excluded?: boolean, errorCount?: number } } = {}
  questionStates: { [questionId: number]: { control?: FormControl, excluded?: boolean, calcError?: boolean,
      deliverablePromptOptions?: DeliverablePromptOption[], feePromptOptions?: FeePromptOption[] } } = {}
  totalErrorCount: number
  isDropdownOpen: Map<number, boolean> = new Map()
  includedCategories: number[]
  deliverableMap: {[deliverableId: number]: LibraryDeliverableEntryDetails} = {}

  constructor(private store: Store,
              private cdr: ChangeDetectorRef,
              private formulaService: FormulaService,
              private approvalFlowService: ApprovalFlowService,
              private dialog: MatDialog,
              private libraryDeliverableEntryDetailsService: LibraryDeliverableEntryDetailsService) {
    this.destroy$ = untilDestroyed()
    this.saveLoading$ = this.store.select(ScopeOverviewSelectors.selectLoadingSaveConfiguration)
    this.submitLoading$ = this.store.select(ScopeOverviewSelectors.selectLoadingSubmitConfiguration)
    this.submitLoaded$ = this.store.select(ScopeOverviewSelectors.selectLoadedSubmitConfiguration)
    this.loadingModifiers$ = this.store.select(CompanyManagementSelectors.selectLoadingModifiers)
  }

  ngOnInit() {
    this.store.dispatch(ScopeOverviewActions.getScenario({ scenarioId: this.scopeVersion.identity.scenario.id }))
    this.store.select(ScopeOverviewSelectors.selectScenario).pipe(this.destroy$()).subscribe((scenario: Scenario) => {
      this.scenario = scenario
      if (this.scenario && !this.scenarioInitialised)
        this.initialiseScenario()
    })
  }

  initialiseScenario() {
    this.formulaService.setExcludedFields([])
    this.formulaService.initialise(this.scenario, Object.entries(this.scopeVersion.dynamicFields).reduce((acc: any[], [key, value]) => {
      acc.push({ name: key, value: value })
      return acc
    }, []))
    let enableNextCategory = true

    if (this.scenario.categories.length) {
      let initialCategoryId: number
      this.scenario.categories = this.scenario.categories.sort((c1, c2) => c1.orderIndex - c2.orderIndex)
      this.scenario.categories.forEach((category) => {
        category.questions = category.questions.sort((q1, q2) => q1.orderIndex - q2.orderIndex)
        this.setExcludedQuestions(category)
        let completed = !!this.configuration[category.id] &&
          !category.questions.find((q) => q.mandatory && !this.questionStates[q.id]?.excluded && !q.value)
        let excluded = category.displayCondition && (this.formulaService.calculate(category.displayCondition) === false)
        this.categoryStates[category.id] = { enabled: enableNextCategory, completed: completed, excluded: excluded }
        if (!this.categoryStates[category.id].completed && !this.categoryStates[category.id].excluded && !initialCategoryId) {
          initialCategoryId = category.id
          enableNextCategory = false
        }
      })
      this.includedCategories = this.scenario.categories.filter((c) => !this.categoryStates[c.id].excluded).map((c) => c.id)
      initialCategoryId = initialCategoryId || (this.scopeVersion.status === StatusType.CONFIG_DRAFT ?
        this.includedCategories[this.includedCategories.length - 1] : this.includedCategories[0])
      this.selectCategory(initialCategoryId)

      this.getAllDeliverablePromptOptions()
    }
    this.scenarioInitialised = true
    this.cdr.detectChanges()
  }

  getAllDeliverablePromptOptions() {
    let deliverableIds = [... new Set(this.scenario.categories
      .flatMap(c => c.questions)
      .flatMap(q => q.deliverablePrompt ? q.deliverablePrompt.deliverablePromptOptions.map(o => o.deliverableId) : []))]

    if (deliverableIds.length) {
      this.libraryDeliverableEntryDetailsService.getDeliverableEntryDetailsByIds(deliverableIds)
        .subscribe({
          next: (deliverables: LibraryDeliverableEntryDetails[]) => {
            deliverables.forEach(d => this.deliverableMap[d.libraryDeliverableEntryId] = d)
            this.cdr.detectChanges()
          }
        })
    }
  }

  dateRequiredValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      return (!control.value?.startDate) ? { required: true }: null;
    }
  }

  selectCategory(categoryId: number) {
    if (this.editable && !this.categoryStates[categoryId].enabled) return
    this.formSubmitted = false
    const category = this.scenario.categories.find((c) => c.id === categoryId)

    category.questions.forEach((q) => {
      this.questionStates[q.id] = this.questionStates[q.id] || {}
      if (q.type === QuestionType.DELIVERABLE && q.deliverablePrompt.deliverablePromptOptions.length &&
        !this.questionStates[q.id].deliverablePromptOptions) {
        this.setupDeliverablePrompt(category, q)
      } else if (q.type === QuestionType.FEE && q.feePrompt.feePromptOptions.length &&
        !this.questionStates[q.id].feePromptOptions) {
        this.setupFeePrompt(category, q)
      } else if (q.type === QuestionType.DATE) {
        this.questionStates[q.id].control = new FormControl(q.value, q.mandatory ? this.dateRequiredValidator() : null)
      } else if (q.type !== QuestionType.FORMULA) {
        this.questionStates[q.id].control = new FormControl(q.value, q.mandatory ? Validators.required : null)
      } else {
        this.calculateFormulaAnswer(q, categoryId)
      }
    })

    if (!this.categoryStates[categoryId].completed) this.setCategoryCompleted(category)
    if (this.categoryStates[categoryId].updated === undefined)
      this.categoryStates[categoryId].updated = !this.categoryStates[categoryId].completed || this.categoryStates[categoryId].errorCount > 0
    if (this.categoryStates[categoryId].errorCount) this.formSubmitted = true
    this.selectedCategoryId = categoryId
    this.cdr.detectChanges()
  }

  setupDeliverablePrompt(category: ScenarioCategory, question: ScenarioQuestion) {
    let selectedIdsArray = question.value?.split(",") || [];
    const promptOptions = question.deliverablePrompt.deliverablePromptOptions
      .map(option => {
        return {
          ...option,
          selected: option.required || selectedIdsArray.includes(option.deliverableId.toString()),
        };
      });
    this.questionStates[question.id].deliverablePromptOptions = promptOptions;
    selectedIdsArray = promptOptions
      .filter(o => o.selected)
      .map(o => o.deliverableId);
    this.questionStates[question.id].control =
      new FormControl(selectedIdsArray, question.mandatory ? [this.promptRequiredValidator()] : []);

    if (!question.value && selectedIdsArray.length) {
      this.setAnswer(question, selectedIdsArray.join(','), true, category.id);
      this.categoryStates[category.id].updated = true
    }
    this.cdr.detectChanges();
  }

  setupFeePrompt(category: ScenarioCategory, question: ScenarioQuestion) {
    let selectedIdsArray = question.value?.split(",") || [];
    const promptOptions = question.feePrompt.feePromptOptions
      .map(option => {
        return {
          ...option,
          selected: option.required || selectedIdsArray.includes(option.feeId.toString()),
        };
      });
    this.questionStates[question.id].feePromptOptions = promptOptions;
    selectedIdsArray = promptOptions
      .filter(o => o.selected)
      .map(o => o.feeId);
    this.questionStates[question.id].control =
      new FormControl(selectedIdsArray, question.mandatory ? [this.promptRequiredValidator()] : []);

    if (!question.value && selectedIdsArray.length) {
      this.setAnswer(question, selectedIdsArray.join(','), true, category.id);
      this.categoryStates[category.id].updated = true
    }
    this.cdr.detectChanges();
  }

  calculateFormulaAnswer(question: ScenarioQuestion, categoryId: number) {
    let result = this.formulaService.calculate(question.formula)
    this.questionStates[question.id] = this.questionStates[question.id] || {}
    if (result instanceof DetailedCellError) {
      this.questionStates[question.id].calcError = true
    } else {
      this.questionStates[question.id].calcError = false
      this.setAnswer(question, result, true, categoryId)
    }
  }

  setCategoryCompleted(category: ScenarioCategory) {
    this.categoryStates[category.id].completed =
      !category.questions.find((q) => q.mandatory && !this.questionStates[q.id]?.excluded && !q.value)
  }

  setExcludedQuestions(category: ScenarioCategory) {
    category.questions.filter((q) => q.displayCondition).forEach((q) => {
      this.questionStates[q.id] = this.questionStates[q.id] || {}
      this.questionStates[q.id].excluded = this.formulaService.calculate(q.displayCondition) === false
    })
  }

  setAnswer(question: ScenarioQuestion, $event: any, autoUpdate = false, categoryId?: number) {
    if (question.value == $event) return
    if (categoryId === undefined) categoryId = this.selectedCategoryId
    question.value = $event
    this.configuration[categoryId] = this.configuration[categoryId] || {}
    this.configuration[categoryId][question.id] = $event
    this.formulaService.updateField(question)

    this.scenario.categories.forEach((c) => {
      this.categoryStates[c.id].excluded = c.displayCondition && (this.formulaService.calculate(c.displayCondition) === false)

      // Update questions with dependant display conditions
      c.questions
        .filter((q) => q.displayCondition &&
          this.formulaService.getReferencedFields(q.displayCondition).includes(question.fieldId?.toLowerCase()))
        .forEach((q) => {
          let wasExcluded = this.questionStates[q.id]?.excluded
          this.questionStates[q.id] = this.questionStates[q.id] || {}
          this.questionStates[q.id].excluded = this.categoryStates[c.id].excluded ||
            (this.formulaService.calculate(q.displayCondition) === false)

          if (this.questionStates[q.id].excluded && !wasExcluded && q.value &&
            q.type !== QuestionType.DELIVERABLE && q.type !== QuestionType.FEE) {
            // Reset the value to null
            this.configuration[c.id][q.id] = null
            q.value = null
            this.questionStates[q.id].control?.setValue(null)
            this.formulaService.updateField(q)
          }
        })

      // Run dependant formulas
      c.questions
        .filter((q) => q.formula &&
          this.formulaService.getReferencedFields(q.formula).includes(question.fieldId?.toLowerCase()))
        .forEach((q) => this.calculateFormulaAnswer(q, c.id))

      if (this.categoryStates[c.id].completed) {
        this.categoryStates[c.id].errorCount =
          c.questions.filter((q) => q.mandatory && !this.questionStates[q.id]?.excluded && !q.value).length
        if (this.categoryStates[c.id].errorCount) this.formSubmitted = true
      } else if (c.id === this.selectedCategoryId) {
        this.setCategoryCompleted(c)
      }
    })

    this.includedCategories = this.scenario.categories.filter((c) => !this.categoryStates[c.id].excluded).map((c) => c.id)

    if (!autoUpdate) {
      this.categoryStates[categoryId].updated = true
      this.totalErrorCount = Object.values(this.categoryStates).filter((c) => c.errorCount).length
      this.store.dispatch(ScopeOverviewActions.updateScenario({ scenario: cloneDeep(this.scenario), hasUpdates: true }))
      this.cdr.detectChanges()
    }
  }

  promptRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedIds = control.value as number[];
      return selectedIds && selectedIds.length > 0
        ? null
        : { required: true };
    };
  }

  private updateSelectedDeliverables(question: ScenarioQuestion) {
    const selectedIds = this.questionStates[question.id].deliverablePromptOptions
      .filter(o => o.selected)
      .map(o => o.deliverableId);
    const selectedIdsString = selectedIds.length > 0 ? selectedIds.join(',') : null;
    if (this.questionStates[question.id].control) {
      this.questionStates[question.id].control.setValue(selectedIdsString);
    }
    this.setAnswer(question, selectedIdsString);
  }

  private updateSelectedFees(question: ScenarioQuestion) {
    const selectedIds = this.questionStates[question.id].feePromptOptions
      .filter(o => o.selected)
      .map(o => o.feeId);
    const selectedIdsString = selectedIds.length > 0 ? selectedIds.join(',') : null;
    if (this.questionStates[question.id].control) {
      this.questionStates[question.id].control.setValue(selectedIdsString);
    }
    this.setAnswer(question, selectedIdsString);
  }

  toggleDropdown(questionId: number): void {
    const currentState = this.isDropdownOpen.get(questionId) || false;
    this.isDropdownOpen.set(questionId, !currentState);
    this.cdr.detectChanges();
  }

  isDeliverableToggleAvailable(question: ScenarioQuestion): boolean {
    return !!this.questionStates[question.id].deliverablePromptOptions
      ?.find(option => !option.selected);
  }

  selectDeliverable(deliverable: any, question: ScenarioQuestion): void {
    deliverable.selected = true;
    this.updateSelectedDeliverables(question);
    if (!this.isDeliverableToggleAvailable(question)) {
      this.toggleDropdown(question.id);
    }
  }

  unselectDeliverable(deliverable: any, question: ScenarioQuestion): void {
    deliverable.selected = false;
    this.updateSelectedDeliverables(question);
  }

  trackByDeliverableId(index: number, deliverable: any): string {
    return `${deliverable.deliverableId}`;
  }

  isFeeToggleAvailable(question: ScenarioQuestion): boolean {
    return !!this.questionStates[question.id].feePromptOptions
      ?.find(option => !option.selected);
  }

  selectFee(fee: any, question: ScenarioQuestion): void {
    fee.selected = true;
    this.updateSelectedFees(question);
    if (!this.isFeeToggleAvailable(question)) {
      this.toggleDropdown(question.id);
    }
  }

  unselectFee(fee: any, question: ScenarioQuestion): void {
    fee.selected = false;
    this.updateSelectedFees(question);
  }

  trackByFeeId(index: number, fee: any): string {
    return `${fee.feeId}`;
  }

  save(scenario: Scenario, category: ScenarioCategory, selectNext: boolean = false) {
    this.formSubmitted = true
    category.questions.forEach(q=> {
      if (!this.configuration[category.id]?.hasOwnProperty(q.id)) {
        this.configuration[category.id] = { ... this.configuration[category.id], [q.id]: "" }
      }
      if (q.type === QuestionType.DELIVERABLE || q.type === QuestionType.FEE) {
        if (this.scopeVersion.status !== StatusType.CONFIG_DRAFT) {
          const { [q.id]: _, ...newConfig } = this.configuration[category.id];
          this.configuration[category.id] = newConfig;
        } else if (this.questionStates[q.id]?.excluded) {
          const { [q.id]: _, ...newConfig } = this.configuration[category.id];
          this.configuration[category.id] = newConfig;
          q.value = null
          this.questionStates[q.id].control?.setValue(null)
        }
      }
    })
    if (!category.questions.some((q) => {
      let control = !this.questionStates[q.id]?.excluded && this.questionStates[q.id]?.control
      if (control && !control.valid) {
        document.getElementById(q.id.toString()).scrollIntoView()
        return true
      }
      return false
    })) {
      let nextCategoryId = this.includedCategories[this.includedCategories.indexOf(category.id) + 1]
      this.store.dispatch(ScopeOverviewActions.saveScopeConfiguration({ scopeId: this.scopeVersion.identity.id, configuration: this.configuration }));
      this.saveLoading$.pipe(
        filter((loading) => !loading),
        take(1)
      ).subscribe(() => {
        if (nextCategoryId) this.categoryStates[nextCategoryId].enabled = true
        this.categoryStates[category.id].updated = false
        if (selectNext) this.selectCategory(nextCategoryId)
      })
    }
  }

  showInvalidModifiersModal(invalidModifiers: ComponentModifier[], modifierResults: { [key: number]: number }) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Invalid modifier value${invalidModifiers.length > 1 ? 's' : ''}`,
        `${invalidModifiers.length > 1 ? 'The following modifier values must be positive numbers' : 'The following modifier value must be a positive number'}` +
        ':\n\n' + invalidModifiers.map((m) => `\u2022 ${m.name}: ${modifierResults[m.id]}\n`).join(''),
        'Close',
        undefined,
        () => {
          dialog.close()
        },
        undefined,
        [], false, false, false, undefined, false, true
      ),
    })
  }

  submit() {
    this.store.dispatch(CompanyManagementActions.getComponentModifiers({ scenarioId: this.scopeVersion.identity.scenario.id }))
    this.store.select(CompanyManagementSelectors.selectModifiersIfLoaded).pipe(
      filter((modifiers) => !!modifiers),
      take(1)
    ).subscribe((modifiers) => {
      let modifierResults: { [key: number]: number } = {}
      modifiers.forEach((m) => modifierResults[m.id] = this.formulaService.calculate(m.formula) as number)
      let invalidModifiers = modifiers
        .filter((m) => (!isNil(modifierResults[m.id]) && !isNumber(modifierResults[m.id])) || modifierResults[m.id] < 0)
      if (invalidModifiers.length) {
        this.showInvalidModifiersModal(invalidModifiers, modifierResults)
      } else {
        this.store.dispatch(ScopeOverviewActions.submitScopeConfiguration({
          scopeId: this.scopeVersion.identity.id,
          modifiers: modifierResults
        }))
        this.submitLoaded$.pipe(
          filter((loaded) => loaded),
          take(1)
        ).subscribe(() => {
          this.onSubmit.emit()
        })
      }
    })
  }

  ngOnDestroy() {
    this.store.dispatch(ScopeOverviewActions.resetScenario())
  }

  protected readonly QuestionType = QuestionType;
  protected readonly trackById = trackById;
  protected readonly StatusType = StatusType;
}
