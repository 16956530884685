<scope-ui-tabs fontSize="13px" (onSelect)="onSelect($event)" *ngIf="route.snapshot.queryParamMap.get('tab') || 'details' as tab">
  <scope-ui-tab #detailsTab tabTitle="Company Details" routeLink="details" [active]='tab === "details"'
                requiredPrivilege="COMPANY_DETAILS__VIEW">
    <iframe *ngIf="detailsTab.active" class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </scope-ui-tab>
  <scope-ui-tab #accountsTab tabTitle="Accounts" routeLink="child-accounts" [active]='tab === "child-accounts"'
                [hidden]="authorisedAsChildCompany() !== null || !(loggedInUser.company.parentCompany == null && parentChildService.childCompanies != null && parentChildService.childCompanies.length > 0)">
    <iframe *ngIf="accountsTab.active" class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </scope-ui-tab>
  <scope-ui-tab #integrationTab tabTitle="Integration Settings" routeLink="integration" [active]='tab === "integration"'
                requiredPrivilege="COMPANY_TRAFFIC_SYSTEM__VIEW">
    <iframe *ngIf="integrationTab.active" class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </scope-ui-tab>
  <scope-ui-tab #settingsTab tabTitle="Application Settings" routeLink="application" [active]='tab === "application"'
                requiredPrivilege="COMPANY_APPLICATION_SETTINGS__VIEW">
    <iframe *ngIf="settingsTab.active" class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </scope-ui-tab>
  <scope-ui-tab #dataTab tabTitle="Data" routeLink="data" [active]='tab === "data"'
                requiredPrivilege="COMPANY_PREDICTION_DATA__VIEW">
    <div *ngIf="dataTab.active" class="manage-tab-wrapper">
      <manage-data class="flex flex-row h-full"></manage-data>
    </div>
  </scope-ui-tab>
  <scope-ui-tab #locationsTab tabTitle="Office Locations" routeLink="office-locations" [active]='tab === "office-locations"'
                requiredPrivilege="OFFICE_LOCATION__VIEW" [hidden]="authorisedAsChildCompany() !== null">
    <iframe *ngIf="locationsTab.active" class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </scope-ui-tab>
  <scope-ui-tab #scopeTypesTab tabTitle="{{'scope_type' | lang }}" routeLink="scope-types" [active]='tab === "scope-types"'
                requiredPrivilege="SCOPE_TYPE__VIEW" requiredSetting="SCOPE__TYPE_VIEW">
    <div *ngIf="scopeTypesTab.active" class="manage-tab-wrapper">
      <scope-types></scope-types>
    </div>
  </scope-ui-tab>
  <scope-ui-tab #clientsTab tabTitle="{{'second_party.p' | lang }}" routeLink="clients" [active]='tab === "clients"'
                requiredPrivilege="SECOND_PARTY__VIEW">
    <div *ngIf="clientsTab.active" class="manage-tab-wrapper">
      <second-parties></second-parties>
    </div>
  </scope-ui-tab>
  <scope-ui-tab #rateCardsTab tabTitle="Rate Cards" routeLink="rate cards" [active]='tab === "rate cards"'
                requiredPrivilege="RATECARD__VIEW && SCOPE__VIEW_RATES">
    <div *ngIf="rateCardsTab.active" class="manage-tab-wrapper">
      <rate-cards></rate-cards>
    </div>
  </scope-ui-tab>
  <scope-ui-tab #membersTab tabTitle="Members" routeLink="members" [active]='tab === "members"'
                requiredPrivilege="COMPANY_MEMBERS__VIEW">
    <iframe *ngIf="membersTab.active" class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </scope-ui-tab>
  <scope-ui-tab #groupsTab tabTitle="Groups" routeLink="groups" [active]='tab === "groups"'
                requiredPrivilege="COMPANY_GROUP__VIEW" [hidden]="authorisedAsChildCompany() !== null">
    <div *ngIf="groupsTab.active" class="manage-tab-wrapper" [class.p-0]="groupId$ | async">
      <groups *ngIf="!(groupId$ | async)"></groups>
      <group *ngIf="groupId$ | async as groupId" [id]="groupId"></group>
    </div>
  </scope-ui-tab>
  <scope-ui-tab #outputsTab tabTitle="{{'doc_template' | lang }} templates" routeLink="output" [active]='tab === "output"'
                requiredPrivilege="OUTPUT_TEMPLATE__VIEW">
    <ng-container *ngIf="outputsTab.active">
      <output-templates *ngIf='!route.snapshot.queryParamMap.get("id")' class="flex flex-col" />
      <document-template
        *ngIf='route.snapshot.queryParamMap.get("type") === "document" && route.snapshot.queryParamMap.get("id")'
        class="flex flex-col h-full" />
      <spreadsheet-template
        *ngIf='route.snapshot.queryParamMap.get("type") === "spreadsheet" && route.snapshot.queryParamMap.get("id")'
        class="flex flex-col h-full" />
    </ng-container>
  </scope-ui-tab>
  <scope-ui-tab #scenarioTab tabTitle="Scenario Pricing Forms" routeLink="scenarios" [active]='tab === "scenarios"'
                [hidden]="!loggedInUser.hasAccessRole(UserRoleLevel.SYSTEM) && !loggedInUser.hasAccessRole(UserRoleLevel.ADMIN)"
                requiredSetting="SCOPE_BY_SCENARIO">
    <ng-container *ngIf="scenarioTab.active">
      <scenarios *ngIf='!route.snapshot.queryParamMap.get("id")'/>
      <ng-container *ngIf='route.snapshot.queryParamMap.get("id") as id'>
        <scenario [id]="parseInt(id)" />
      </ng-container>
    </ng-container>
  </scope-ui-tab>
</scope-ui-tabs>
